<template>
  <div class="now-chat-box">
    <heads
      :hideInput="hideInput"
      page="QuestionChat"
      @onClickRight="onClickRight"
      left="/home"
    ></heads>
    <question-content
      :list="questionChatList"
      @saveRecord="saveRecord"
      @getQuestion="getQuestion"
      @getAllQuestion="getAllQuestion"
      ref="questionContent"
    ></question-content>
    <!-- 返回顶部 -->
    <div class="scroll-top" v-show="showScrollIcon" @click="scrollToTop"></div>
    <chat-input @sendText="sendText"></chat-input>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Header from "@/components/Header";
import ChatInput from "@/components/ChatInput";
import QuestionContent from "./Components/QuestionContent";
import { saveQuestionRecord, QuestionMatch, deptQuestion, getBrokerQR } from "@/fetch/api/chat.js";
export default {
  name: "nowchat",
  components: {
    heads: Header,
    ChatInput,
    QuestionContent,
  },
  data() {
    return {
      broker_id: localStorage.getItem("broker_id"),
      from: this.$route.params.type,
      conversation_uuid: "",
      hideInput: this.$route.query.underwriting == 1 ? false : true,
      jyb_id: localStorage.getItem("jyb_id"),
      showScrollIcon:false,
      deviceHeight:0
    };
  },
  computed: {
    ...mapState({
      questionChatList: (state) => state.chat.questionChatList,
    }),
  },
  methods: {
    ...mapMutations(["SET_QUESTION_CHAT_LIST"]),
    sendText(text) {
      if (text.trim() == "") {
        this.$toast("不可发送空消息")
        return
      }
      // 匹配问题然后存储数据库
      let res = this.$refs.questionContent.checkGategory(text)
      if (!res){
        this.saveRecord(text, 1)
        this.getAllQuestion(text, false)
      }

    },
    saveRecord(content, speaker, type=''){
      let param = {
        broker_id: this.broker_id,
        content,
        first_conversation: "0", //0非首次进入，1首次进入
        // conversation_uuid: this.conversation_uuid,//首次进入无id
        speaker,
        is_collected: "0",
        online_data_id: null,
        underwriting: 2, 
        type
      };
      this.SET_QUESTION_CHAT_LIST({ type: "add", data: param });
      saveQuestionRecord(param).then((res) => {
      if (res.code != 200) {
          this.$toast(`${res.error}`);
        }
      });
    },
    getAllQuestion(match, answer){
      deptQuestion({"userId": this.jyb_id}).then((res)=>{
        let matchData = []  //匹配问题列表
        let quesClassify = new Set() // 匹配分类列表
        let naviClassify = new Set() // 匹配办事导航分类
        let deptList = new Set()     // 匹配部门分类
        let allMatchDic = {}  // 每个问题对应结果
        if (res.code == 10000) {
          // 处理应知应会数据
          for (let i=0;i<res.data.shouldList.length;i++){
            // 添加匹配问题
            let quesObj = res.data.shouldList[i]
            matchData.push(quesObj.question)  
            allMatchDic[quesObj.question] = quesObj
            // 添加匹配分类
            quesClassify.add(quesObj.classify)
            deptList.add(quesObj.dept)
          }
          // 处理办事导航数据
          for (let i=0;i<res.data.navigationPeopleRespList.length;i++){
            let programObj = res.data.navigationPeopleRespList[i]
            matchData.push(programObj.program)
            matchData.push(programObj.address)
            matchData.push(programObj.people)
            naviClassify.add(programObj.classify)
            //岗位地址和姓名对应同一个人
            allMatchDic[programObj.program] = programObj
            allMatchDic[programObj.address] = programObj
            allMatchDic[programObj.people] = programObj
          }
          // 如果有匹配数据就进行匹配
          if (match){
            // 合并匹配数据
            matchData = Array.from(new Set(matchData)).concat(Array.from(quesClassify)).concat(Array.from(naviClassify)).concat(Array.from(deptList))
            let param = {"question": match, "question_list":matchData}
            QuestionMatch(param).then((matchRes)=>{
              let answerText = ""
              if (matchRes.code==200){//命中问题列表
                // 检查匹配结果有没有在分类中
                let classifyFlag = this.$refs.questionContent.checkGategory(matchRes.result, false)
                if (!classifyFlag){ //没在
                  if (deptList.has(matchRes.result)){ // 输入的为部门
                    let deptQs = [{"canClick": false, "text": `您是否想要找部门：${matchRes.result} 下的问题`}]
                    for (let i=0;i<res.data.shouldList.length;i++){
                      if(matchRes.result == res.data.shouldList[i].dept){
                        deptQs.push({"canClick": true, "text": res.data.shouldList[i].question})
                      }
                    }
                    let deptData = {"answer": deptQs, "type": "answer"}
                    this.saveRecord(deptData, 0, "answer")
                    return 
                  }
                }else{ // 分类
                  return 
                }
                if ("people" in allMatchDic[matchRes.result]){  // 命中办事导航
                  let user = allMatchDic[matchRes.result]
                  answerText = allMatchDic[matchRes.result].program + " 负责人: " + user.people + "<br>下方是相关人员名片信息，请查收"
                  this.sendNameCard(user)
                }else{  // 应知应会
                  answerText = allMatchDic[matchRes.result].answer
                }
              }else{
                answerText = "Bebot没有找到您问的问题呢！您可以换一种方法进行提问~"
              }
              
              let result = [{"canClick": false, "text": answerText}]
              if (matchRes.like.length > 0 ){
                result[0].text =  result[0].text + "<br><br>您可能还想问：<br>"
                let res_like = matchRes.like
                res_like = Array.from(new Set(res_like))
                let like_result = res_like.map((like)=>{
                  return {"canClick": true, "text": like}
                })
                result = result.concat(like_result.slice(0, 3))
              }
              let data = {"answer": result, "type": "answer"}
              this.saveRecord(data, 0, "answer")
            })
          }
          // 如果是点击问题就直接问答列表取数据
          if (answer){
            let data = allMatchDic[answer]
            let content = {}
            if(data){
              if ("people" in data){
                let answerText = data.program + " 负责人: " + data.people + "<br>下方是相关人员名片信息，请查收"
                content = {"answer":[{'canClick': false, 'text':answerText}]}
                this.sendNameCard(data)
              }else{
                content = {"answer":[{'canClick': false, 'text':data.answer}]}
              }
            }else{ // 在问答对象中没有找到 则去匹配
              this.getAllQuestion(answer, false)
              return
            }
            
            setTimeout(()=>{
              content["type"] = "answer"
              this.saveRecord(content, 0, "answer")
            }, 300)
          }
        } else {
          this.$toast("请求数据源失败，请稍后重试。。。");
        }
      })
    },
    getQuestion(param, flag){
      deptQuestion(param).then((res)=>{
        let questionList = []
        if (res.code == 10000) {
          // flag=='work' 为 点击 办事导航 获取办事导航列表
          let dataList = flag=='work'? res.data.navigationPeopleRespList : res.data.shouldList
          questionList = dataList.map((q)=>{
            return flag=='work'? q.program:q.question
          })
          questionList = Array.from(new Set(questionList))
          if (questionList.length==0){//分类下的问题已全部删除，重新获取分类
            this.$refs.questionContent.getDept()
          }
          this.$refs.questionContent.setTmpQuestion(questionList)
        }
        let data = {"questionList": questionList.slice(0,3), "type":"questionList"}
        this.saveRecord(data, 0);
      })
    },
    sendNameCard(user){ //获取用户头像和二维码发送名片
      getBrokerQR({"mobile": user.mobile}).then((userData)=>{
        if (userData.code == 10000){
          user["profilePig"] = userData.data.profilePig
          user["peopleFrofile"] = userData.data.peopleFrofile
          user["wechatUrl"] = userData.data.wechatUrl
        }
      })
      //发送名片信息
      setTimeout(()=>{
        user['type'] ="nameCard"
        this.saveRecord(user, 0) 
      },500)
    },
    handleScroll(){
      let container = document.getElementById("chatView");
      if (container.scrollHeight > this.deviceHeight) {
        this.showScrollIcon = true
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        setTimeout(() => {
          let container = document.getElementById("chatView");
          if (container) {
            container.scrollTop = container.scrollHeight;
          }
        }, 0);
      });
    },
    scrollToTop() {
      this.$nextTick(() => {
        setTimeout(() => {
          let container = document.getElementById("chatView");
          if (container) {
            container.scrollTop = 0;
          }
        }, 0);
      });
    },
    onClickRight() {
      this.$router.push({
        path: "/chat/history?type=QuestionChat",
        query: {
          hideHistoryText: 1,
        },
      });
    },
    changeNowChatList(data) {
      this.SET_QUESTION_CHAT_LIST({ type: "upData", data: data });
    },
  },
  updated() {
    this.scrollToBottom();
  },
  created() {
    this.scrollToBottom();
  },
  mounted(){
    let container = document.getElementById("chatView")
    this.deviceHeight = container.scrollHeight
    container.addEventListener('scroll', this.handleScroll)
  }
};
</script>

<style lang="scss" scoped>
.scroll-top{
  position: fixed;
  background-image: url("../../assets/images/toTop.png");
  background-size: 100%;
  right: 0.37rem;
  bottom: 2.69rem;
  width: 1.06rem;
  height: 1.06rem;
}
</style>
